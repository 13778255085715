<template>
  <div id="step-builder" :class="{'mobile': $vuetify.breakpoint.smAndDown}">
    <div id="steps-scroll" class="step-container">


    <template v-if="currentM !== 'undefined'">
        <v-card color="grey lighten-2" elevation="0"  class="pa-2 mb-4 options" data-v-step="options">

          <div class="d-flex align-center justify-space-between">
            <div class="">
              <!-- <v-icon>pause_circle_outline</v-icon>  -->
              <div class="title grey--text text--darken-4 d-flex align-center pa-3">
                Visibility <v-btn text small color="secondary darken-1" @click="morePublish = !morePublish">
                  <template v-if="morePublish">less</template>
                  <template v-else>more</template>
                </v-btn>
              </div>
            </div>
            <div class="flex-auto">
              <!-- <v-radio-group v-model="local_published" row class="pa-0 ma-0" hide-details >
                <v-radio label="Private" :value="false" color="secondary darken-2"></v-radio>
                <v-radio label="Public" :value="true" color="secondary darken-2"></v-radio>
              </v-radio-group> -->
              <v-btn-toggle tile group color="secondary darken-3"  v-model="local_published">
                <v-btn small class="pa-0" :value="false"><v-icon>visibility_off</v-icon> Private</v-btn>
                <v-btn small class="pa-0" :value="true"><v-icon>public</v-icon> Public</v-btn>
              </v-btn-toggle>
            </div>
          </div>
            <p class="option-text caption px-3" v-if="morePublish">You can keep this video private, but you'll help everyone by making it public ;)</p>

          <hr>

          <div class="d-flex align-center justify-space-between">
            <div class="flex-auto">
              <!-- <v-icon>pause_circle_outline</v-icon>  -->
              <div class="title grey--text text--darken-4 d-flex align-center pa-3">
                Default Step View <v-btn text small color="secondary darken-1" @click="moreStepView = !moreStepView">
                  <template v-if="moreStepView">less</template>
                  <template v-else>more</template>
                </v-btn>
              </div>
            </div>
            <div class="flex-auto">
              <!-- <v-radio-group v-model="local_stepView" row class="pa-0 ma-0" hide-details >
                <v-radio label="Single" value="step" color="secondary darken-2"></v-radio>
                <v-radio label="Multi" value="steps" color="secondary darken-2"></v-radio>
              </v-radio-group> -->
              <v-btn-toggle tile group color="secondary darken-3"  v-model="local_stepView">
                <v-btn  class="pa-0" value="step"><v-icon>looks_one</v-icon> Single</v-btn>
                <v-btn  class="pa-0" value="steps"><v-icon>horizontal_split</v-icon> Multi</v-btn>
              </v-btn-toggle>
            </div>
          </div>
          <div class="option-text caption px-3 mb-2" v-if="moreStepView">
            This sets an "initial" view, but viewers can still choose between:
            <ul>
              <li>"Single" shows a focused, single card that changes with each step.</li>
              <li>"Multi" shows all steps and allows views to click on any one to skip around.</li>
            </ul>
          </div>

            <hr>

            <div class="d-flex align-center justify-space-between">
              <div class="">
                <!-- <v-icon>pause_circle_outline</v-icon>  -->
                <div class="title grey--text text--darken-4 d-flex align-center pa-3">
                  Allow Skipping Intro <v-btn text small color="secondary darken-1" @click="moreSkip = !moreSkip">
                    <template v-if="moreSkip">less</template>
                    <template v-else>more</template>
                  </v-btn>
                </div>
              </div>
              <div class="flex-auto">
                <v-switch inset color="secondary darken-2" class="ma-0 pa-0 no-messages" v-model="local_skipIntro" label="show button">
                </v-switch>
              </div>
            </div>
            <p class="option-text caption px-3" v-if="moreSkip">This puts a "Netflix" style button on screen for viewers to skip to the first step.</p>
        </v-card>


        <v-layout v-bind:id="`step_${indexS}`"
          align-start justify-start
          class="steps mb-4"
          :class="{'active': active == indexS }"
          v-for="(step, indexS) in local_steps" :key="indexS">


          <v-flex>

            <!-- card -->
            <stepbuilder-card
            ref="cards"
              :step="step"
              :skipIntro="skipIntro"
              :stepView="stepView"
              :published="published"
              :timestamp="step.timestamp"
              :indexx="indexS"
              :duration="duration"
              :timeIn="getTimeOut()"
              :nextTimestamp="getNextTimestamp(indexS, step)"
              :currentMarkerIndex="currentMarker.index"
              :stepLength="video.steps.length"
              @onStepClicked="onStepClicked"
              @deleteStep="deleteStep"
              @updateVideoTime="updateVideoTime">
            </stepbuilder-card>
          </v-flex>
        </v-layout>

      </template>


    </div>

    <v-snackbar
      v-model="snackbar.show" :color="snackbar.color" dark>
      {{ snackbar.message }}
      <v-btn text color="white" @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>

  </div>
</template>

<script>
const stepbuilderCard = () => import('@/components/shared/stepbuilder-cards.vue')
import { mapGetters } from 'vuex'
// import stepbuilderCard from "@/components/shared/stepbuilder-cards";
export default {
  name: "step-builder",
  props: ['steps','stepView','currentMarker', 'isPlaying', 'skipIntro', 'published', 'duration', 'timeIn'],
  components:{stepbuilderCard},
  computed:{
    ...mapGetters({
      video:'GET_BUILD_VIDEO',
    }),
  },
  data(){
    return{
      avatarSize: 32,
      active:null,
      currentM: {time:0},
      snackbar: {'show':false},
      valid:{},
      nextTimestamp: null,
      moreSkip: false,
      moreStepView: false,
      morePublish: false,
      local_stepView:'step',
      local_skipIntro:true,
      local_published:true,
      local_steps: [],
    }
  },
  watch:{
    currentMarker(newV, oldV){
      // console.log('step');
      this.currentM = newV;
    },
    timeIn:function(newV,oldV){
      if(newV.timeupdate !== undefined){
        this.getNextTimestamp();
        this.getTimeOut();
      }
    },
    local_stepView:function(newV,oldV){
      this.updateStep({stepView: newV})
    },
    local_skipIntro:function(newV,oldV){
      this.updateStep({skipIntro: newV})
    },
    local_published:function(newV,oldV){
      this.updateStep({published: newV})
    },
    steps:{
      handler: function (val, oldVal) {
        process.env.NODE_ENV === "development" ? console.log( 'new val from steps', val ) : null;
        // Return the object that changed
          this.local_steps = val;
      },
      deep: true
    }
  },
  filters:{
    timeInHours: function(value) {
        let hours =  parseInt(Math.floor(value / 360));
        let minutes = parseInt(Math.floor((value - (hours * 360)) / 60));
        let seconds= parseInt((value - ((hours * 360) + (minutes * 60))) % 60);

        let dHours = (hours > 9 ? hours : '0' + hours);
        let dMins = (minutes > 9 ? minutes : '0' + minutes);
        let dSecs = (seconds > 9 ? seconds : '0' + seconds);

        return dHours + ":" + dMins + ":" + dSecs;
    }
  },
  methods: {
    onStepClicked(data){
      // vThis.$logEvent('Viewed','Started', `${vThis.video.videoId} - ${vThis.video['.key']}`);
      this.$emit('onStepClicked', data)
    },
    isPast(time){
      return this.currentMarker > time
    },
    toggleSkipIntro(){
      this.$emit('toggleSkipIntro',this.skipIntro);
    },
    getNextTimestamp(){
      return this.currentMarker.index+1 < this.video.steps.length ? this.video.steps[this.currentMarker.index+1].timestamp : this.duration
    },
    getTimeOut(){
      let timeOut = 0;
      // why did i do this....
      if(!this.$isEmpty(this.currentMarker)){
        // process.env.NODE_ENV === "development" ? console.log( 'getTimeOut', this.currentMarker, this.video.steps ) : null;
        if(this.currentMarker.step.time > this.video.steps[this.currentMarker.index].timestamp){
          timeOut = this.video.steps[this.currentMarker.index].timestamp
        }
        else{
          timeOut = this.timeIn;
        }
        return timeOut
      }
    },
    updateVideoTime(newTime){
      let vThis = this;
      this.buildStep(newTime).then((data)=>{
        let video = data.video
        process.env.NODE_ENV === "development" ? console.log( 'video', video, data.foundIndex ) : null;
        if(data.foundIndex >= 0){
          vThis.$store.dispatch('setBuildVideo', {video}).then(()=>{
            let stepNum = data.foundIndex >= 0 ? data.foundIndex : data.video.steps.length
            let el = document.getElementById(`card_${stepNum}`);
            process.env.NODE_ENV === "development" ? console.log( 'el', el ) : null;
            vThis.$scrollTo(el, '1000', {container:'#step-builder', easing: 'ease-out', offset: -'60'});
            vThis.$emit('onStepChange', video.steps);
          });
        }
      }).catch(e=>{
        console.log('step already exists', e);
      })
    },
    buildStep(time){
      let vThis = this;
      let newArray = JSON.parse(JSON.stringify(this.video));

      return new Promise((resolve, reject) => {
      // let stepIndex = vThis.video.steps.length+1;
      let foundDuplicate = vThis.steps.findIndex(el => time == el.timestamp);

      process.env.NODE_ENV === "development" ? console.log( 'foundDuplicate', foundDuplicate ) : null;

      function compare(a, b) {
        return a.timestamp > b.timestamp ? 1: -1
      }

      if(foundDuplicate >= 0){
        let data = {'foundIndex': -1}
        reject(data);
       vThis.snackbar ={
         'show': true,
         'message': 'Step Already Exists!',
         'color':'error',
         'action': 'close'
       }
       vThis.snackbarTimer = setTimeout(() => {
         vThis.snackbar.show = false;
       },5000)
      }
      else{
        let sorted = vThis.local_steps.sort(compare);
        let foundIndex = sorted.findIndex(el => time < el.timestamp);
        newArray.steps = sorted;
        let data = {'video': newArray, 'foundIndex': foundIndex}
        process.env.NODE_ENV === "development" ? console.log( 'ok ok ok ', newArray, foundIndex, data) : null;
        resolve(data);
        }

      });
    },
    updateStep(data){
      let vThis = this;
      process.env.NODE_ENV === "development" ? console.log( 'data from updateStep', data ) : null;
      let video = JSON.parse(JSON.stringify(this.video));
      video[Object.keys(data)[0]] = Object.values(data)[0];
      // console.log('newArray', newArray);
      vThis.$store.dispatch('setBuildVideo', {video}).then(()=>{

      });
    },
    // deleteStep(data){
    //   process.env.NODE_ENV === "development" ? console.log( 'delete step', data ) : null;
    //   let vThis = this;
    //   let videoArray = JSON.parse(JSON.stringify(vThis.video));
    //   videoArray.steps.splice(data.index,1);
    //   process.env.NODE_ENV === "development" ? console.log( videoArray.steps) : null;
    //   // vThis.$emit('onStepChange', newArray);
    // }
    deleteStep(data){
      this.$emit('deleteStep', data);
    },
  },
  mounted(){
    this.local_stepView = this.stepView;
    this.local_skipIntro = this.skipIntro;
    this.local_published = this.published;

    this.local_steps = JSON.parse(JSON.stringify(this.steps));
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#step-builder{
  // padding-bottom: 90vh;
  height: 100%;
  overflow: scroll;
  padding: 2vh 10px 70vh;
  &.mobile{
    height: auto;
    padding-bottom: 100px;
    padding-top: 0;
  }

  .steps{
    .controls > .v-btn{
      visibility: hidden;
    }
    &:hover{
      .controls > .v-btn{
        visibility: visible;
      }
    }
    .layout{
      position: relative;
      &:after {
        position: absolute;
        content: '';
        left: 20px;
        top: 0;
        width: 1px;
        height: 100%;
        background-color: rgba(0,0,0,.1);
      }
      time{
        line-height: 20px;
      }
    }

    .v-avatar{
      margin: 0 8px 5px 5px;
      .play{
        display: none;
      }
      .past{
        opacity: .6;
      }
    }
    ul > li > p{
      margin: 0;
    }
  }
  .v-text-field > .v-input__control > .v-input__slot:before, .v-text-field > .v-input__control > .v-input__slot:after {
    z-index: -1;
    opacity: 0;
  }

  hr{
    opacity: .4;
  }
  .v-switch{
    padding: 0!important;
  }
  .options{
    .title{
      font-size: 16px!important;
    }
  }
  .v-card{
    margin: 0;
  }
  .v-input{
    .v-input__slot{
      margin: 0;
    }
  }
  .v-text-field__details{
    display: none;
  }
  .no-messages{
    .v-messages{
      display: none!important;
    }
  }
}
</style>

<!--
<tiptap-vuetify
  v-model="steps[index].description"
  :extensions="extensions"
  :toolbar-attributes="{ color: 'yellow' }"
>
  <template #toolbar="{buttons, commands, isActive}">
    <div class="">

    <v-btn icon
class="menubar__button"
:class="{ 'is-active': isActive.bold() }"
@click="commands.bold"
>
<v-icon>format_bold</v-icon>
</v-btn>

<v-btn icon
class="menubar__button"
:class="{ 'is-active': isActive.italic() }"
@click="commands.italic"
>
<v-icon>format_italic</v-icon>
</v-btn>

<v-btn icon
class="menubar__button"
:class="{ 'is-active': isActive.strike() }"
@click="commands.strike"
>
<v-icon>format_strikethrough</v-icon>
</v-btn>

<v-btn icon
class="menubar__button"
:class="{ 'is-active': isActive.underline() }"
@click="commands.underline"
>
<v-icon>format_underlined</v-icon>
</v-btn>

<v-btn icon
class="menubar__button"
:class="{ 'is-active': isActive.link() }"
@click="commands.link"
>
<v-icon>link</v-icon>
</v-btn>

<v-btn icon
class="menubar__button"
:class="{ 'is-active': isActive.paragraph() }"
@click="commands.paragraph"
>
<v-icon>paragraph</v-icon>
</v-btn>

<v-btn icon
class="menubar__button"
:class="{ 'is-active': isActive.heading({ level: 1 }) }"
@click="commands.heading({ level: 1 })"
>
H1
</v-btn>

<v-btn icon
class="menubar__button"
:class="{ 'is-active': isActive.heading({ level: 2 }) }"
@click="commands.heading({ level: 2 })"
>
H2
</v-btn>

<v-btn icon
class="menubar__button"
:class="{ 'is-active': isActive.bullet_list() }"
@click="commands.bullet_list"
>
<v-icon>format_list_bulleted</v-icon>
</v-btn>

<v-btn icon
class="menubar__button"
:class="{ 'is-active': isActive.ordered_list() }"
@click="commands.ordered_list"
>
<v-icon>format_list_numbered</v-icon>
</v-btn>

<v-btn icon
class="menubar__button"
:class="{ 'is-active': isActive.blockquote() }"
@click="commands.blockquote"
>
<v-icon>format_quote</v-icon>
</v-btn>

<v-btn icon
class="menubar__button"
:class="{ 'is-active': isActive.code() }"
@click="commands.code"
>
<v-icon>code</v-icon>
</v-btn>

    </div>

  </template>
</tiptap-vuetify>
 -->
